import { Helmet } from "react-helmet";
import turtles from "../assets/images/turtles.png";

function Piercing() {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Hawaii | Piercing</title>
      </Helmet>
      <div>
        <section id="piercing" className="coffee_area mt-60 mb-30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_title text-center pb-30">
                  <h4 className="title">Piercing</h4>
                  <img className="turtles" alt="turtles" src={turtles} />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3>WAIKIKI</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $60
                      <br />
                      Conch: $80
                      <br />
                      Industrial: $100
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $60
                      <br />
                      Helix / Anti-Helix: $60
                      <br />
                      Snug: $60
                      <br />
                      Smiley: $80
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Eyebrow: $80
                      <br />
                      Tongue: $60
                      <br />
                      Septum: $100
                      <br />
                      Labret: $80
                      <br />
                      Lip: $80 ($100 for 2 / snakebites)
                      <br />
                      Medusa: $80
                      <br />
                      Monroe: $80
                      <br />
                      Ashley: $60
                      <br />
                      Nose: $60
                      <br />
                      Tooth Gems: $60 Gold: $150
                      <br />
                      Angel Bites: $200
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $60
                      <br />
                      Nipple: $60 / $100 for set
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3 className="mb-20">KALAKAUA, KAILUA, KAPOLEI</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $80
                      <br />
                      Conch: $80
                      <br />
                      Industrial: $100
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $80
                      <br />
                      Helix / Anti-Helix: $60
                      <br />
                      Snug: $80
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Nostril: $60
                      <br />
                      Eyebrow: $80
                      <br />
                      Tongue: $80
                      <br />
                      Septum: $100
                      <br />
                      Labret: $80
                      <br />
                      Lip: $80
                      <br />
                      Medusa: $100
                      <br />
                      Monroe: $80
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $80
                      <br />
                      Single Nipple: $80 / $120 for set
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3 className="mb-20">SARATOGA</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $70
                      <br />
                      Conch: $80
                      <br />
                      Industrial: $100
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $80
                      <br />
                      Helix / Anti-Helix: $60
                      <br />
                      Snug: $70
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Nostril: $60
                      <br />
                      Eyebrow: $80
                      <br />
                      Tongue: $100
                      <br />
                      Septum: $100
                      <br />
                      Labret: $60
                      <br />
                      Lip: $80 / $100 for 2
                      <br />
                      Medusa: $80
                      <br />
                      Monroe: $80
                      <br />
                      Ashley: $80
                      <br />
                      Smiley: $80
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $80
                      <br />
                      Nipple: $60 / $100 for set
                      <br />
                      VCH: $120 (2Bit Only)
                      <br />
                      Christina: $200 (2Bit Only)
                      <br />
                      Dermals: $100 / $160 for set of 2 (2Bit Only)
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3 className="mb-20">AIEA</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $80
                      <br />
                      Conch: $80
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $80
                      <br />
                      Helix / Anti-Helix: $80
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Nostril: $80
                      <br />
                      Eyebrow: $80
                      <br />
                      Lip: $80
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $80
                      <br />
                      Nipple: $60 / $100 for set
                      <br />
                      VCH: $120 (2Bit Only)
                      <br />
                      Christina: $200 (2Bit Only)
                      <br />
                      Dermals: $100 / $160 for set of 2 (2Bit Only)
                      <br />
                      Genitals: $150 / $500 (2Bit Only)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Piercing;
